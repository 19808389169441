/* WhatsApp Hover Effect */
.wtsp:hover {
  background-image: radial-gradient(
    circle at 30% 107%,
    #25d366 0%,
    #20c857 40%,
    #1eb943 100%
  ); /* WhatsApp green gradient */
  color: #fff;
}

/* LinkedIn Hover Effect */
.li:hover {
  background-image: radial-gradient(
    circle at 30% 107%,
    #0077b5 0%,
    #006097 40%,
    #004d74 100%
  ); /* LinkedIn blue gradient */
  color: #fff;
}

/* YouTube Hover Effect */
.yt:hover {
  background-image: radial-gradient(
    circle at 30% 107%,
    #ff0000 0%,
    #cc0000 50%,
    #800000 100%
  ); /* YouTube red gradient */
  color: #fff;
}

.wrapper {
  position: relative;
  left: 30%;
}

.wrapper :is(.button, .link) {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  width: 50px;
  height: 50px;
  background-color: #ffffff;
  color: #151515;
  border-radius: 50%;
}

.button {
  position: absolute;
  top: 0;
  left: 0;
  border: none;
  cursor: pointer;
  z-index: 10;
}

.two {
  z-index: 9;
  margin: -32px;
}

.activ .button,
.button:hover {
  outline: 3px solid #0561ee;
  outline-offset: -4px;
}

.item {
  position: absolute;
  list-style: none;
  transition: transform 0.5s;
  transition-delay: var(--d);
}

.wrapper.activ .item:nth-child(1) {
  transform: translateX(-65px);
}

.wrapper.activ .item:nth-child(2) {
  transform: translateY(-65px);
}

.wrapper.activ .item:nth-child(3) {
  transform: translateX(65px);
}

.link:hover {
  color: #fff;
  transition: background-color 0.5s;
}
