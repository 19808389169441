.single-hero-slide {
  background-color: #011e1b;
  padding-top: 200px;
  padding-bottom: 220px;
  font-family: "Josefin Sans", sans-serif;
}
.anime {
  opacity: 0;
  filter: blur(5px);
  transform: translateY(35%);
  transition: all 1s;
}
.hero-slide-left {
  padding-right: 60px;
}

.hero-slide-left h2 {
  font-size: 36px;
}

.hero-slide-left h1 {
  font-size: 62px;
  line-height: 75px;
  margin-bottom: 20px;
}

.hero-slide-left p {
  color: #ddd !important;
  font-family: "Roboto Slab", serif;
}

.hero-slide-left button {
  margin-top: 40px;
  font-weight: 700;
  outline: none !important;
  cursor: pointer;
  font-family: "Roboto Slab", serif;
}

.heroTeeth {
  max-width: 100%;
  background-repeat: no-repeat;
  background-position: bottom;
  background-size: contain;
}

.theme-btn {
  display: inline-block;
  border: 1px solid #ed1c25;
  height: 61px;
  text-align: center;
  line-height: 55px !important;
  padding: 0 55px;
  background: #ed1c25;
  text-transform: capitalize;
  position: relative;
  z-index: 2;
  border-radius: 3px;
  color: #fff;
  transition: 0.4s;
  font-weight: 600;
}

.btn-fill {
  margin-right: 20px;
}

.btn-fill:hover {
  background: #fff;
  color: #000 !important;
  border-color: #fff;
}

.bth-blank {
  border-color: #fff;
  background: transparent;
}

.bth-blank:hover {
  border-color: #ed1c25;
  color: #fff !important;
  background: #ed1c25;
}

.animate-img {
  position: absolute;
  bottom: 0;
  left: 50%;
  transform: translateX(-50%);
  z-index: 22;
}

.animate-img .aimg1 {
  left: 210px;
  position: relative;
  animation: movebounce 2s ease-in-out infinite;
  animation-delay: 100ms;
  animation-direction: alternate;
  bottom: 15px;
}

.animate-img .aimg2 {
  position: relative;
  left: 265px;
  top: 30px;
  animation-delay: 200ms;
  animation: movebounce 2.5s ease-in-out infinite;
  animation-direction: alternate;
}

@keyframes movebounce {
  0% {
    transform: translateY(0px);
  }
  50% {
    transform: translateY(20px);
  }
  100% {
    transform: translateY(0px);
  }
}

/* for mobile responsive */
@media (max-width: 1024px) {
  /* banner */
  .animate-img .aimg1 {
    left: 209px;
    top: 133px;
  }

  .animate-img .aimg2 {
    left: 265px;
    top: 127px;
  }
}

@media (max-width: 992px) {
  /* banner */
  .animate-img .aimg1 {
    left: -25px;
    top: 381px;
  }

  .animate-img .aimg2 {
    left: 27px;
    top: 417px;
  }

  .hero-slide-left {
    padding: 0;
  }
}

@media (max-width: 769px) {
  /* banner */
  .animate-img .aimg1 {
    left: -25px;
    top: 381px;
  }

  .animate-img .aimg2 {
    left: 27px;
    top: 429px;
  }
}

@media (max-width: 576px) {
  .hero-slide-right {
    margin-top: 70px;
  }
}

@media (max-width: 512px) {
  /* banner */
  .animate-img .aimg1 {
    left: -92px;
    top: 605px;
  }

  .animate-img .aimg2 {
    left: 102px;
    top: 463px;
  }
}

@media (max-width: 461px) {
  /* banner */
  .animate-img .aimg1 {
    left: -91px;
    top: 682px;
  }

  .animate-img .aimg2 {
    left: 94px;
    top: 555px;
  }
}

@media (max-width: 445px) {
  /* banner */
  .animate-img .aimg1 {
    left: -88px;
    top: 799px;
  }

  .animate-img .aimg2 {
    left: 94px;
    top: 671px;
  }

  .banner-btn {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }
}

@media (max-width: 426px) {
  .single-hero-slide {
    padding-top: 80px;
    padding-bottom: 40px;
  }

  .hero-slide-left button {
    margin: 1rem 0;
  }

  .animate-img .aimg1 {
    top: 605px;
    left: -85px;
  }

  .animate-img .aimg2 {
    left: 94px;
    top: 467px;
  }
}

@media (max-width: 376px) {
  .single-hero-slide {
    padding-bottom: 85px;
  }

  .animate-img .aimg1 {
    top: 597px;
    left: -72px;
  }

  .animate-img .aimg2 {
    left: 94px;
    top: 462px;
  }
}

@media (max-width: 321px) {
  .container-head {
    max-width: 90%;
  }

  .animate-img .aimg1 {
    max-width: 65%;
    top: 575px;
    left: -61px;
  }

  .animate-img .aimg2 {
    max-width: 65%;
    left: 80px;
    top: 469px;
  }
}
