.achivement-wrap {
    padding:  120px 0;
    margin-bottom: 120px;
    background: #011E1B;
}

.btn-unfill {
    border-color: #fff !important;
    background: transparent !important;
}

.btn-unfill:hover {
    border-color: #ED1C25 !important;
    background: #ED1C25 !important;
}

.play-btn {
    padding-right: 13px;
}

.achivement-txt p {
    font-size: 17px;
    margin-bottom: 45px;
}

.single-funfact {
    background-image: url('../../../Images/single-fun-bg.png');
    background-repeat: no-repeat;
    background-position: top;
    background-size: cover;
    padding-top: 70px;
    padding-bottom: 30px;
    margin-top: 30px;
}

.single-funfact span {
    font-size: 24px;
    font-weight: 700;
    position: relative;
    line-height: 30px;
    display: inline-block;
}


/* for mobile responsive */
@media only screen and (max-width: 426px){
    .achivement-txt p {
        width: 100% !important;
    }

    .more-tool {
        display: flex;
        flex-direction: row;
        justify-content: space-around;
        align-items: center;
    }

    .theme-btn {
        line-height: 0 !important;
        padding: 0 20px !important;
    }
}

@media only screen and (max-width: 321px){
    .watchBtn {
        padding: 0 0px !important;
        font-size: 12px;
    }
}