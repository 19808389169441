/* @import url('https://fonts.googleapis.com/css2?family=Fira+Code:wght@300;400;500;600;700&family=Lora:ital,wght@0,400;0,500;0,600;1,400&family=Roboto+Slab:wght@300;400;500;600;700&display=swap');

.container-head {
    background: #fff;
    border-radius: 10px;
    transition: all .5s ease-in-out;
    padding: 0 30px;
    margin-top: 25px;
}

.head-bg {
    background-color: #011E1B;
}

.list-item {
    padding: 25px 0;
    display: inline-block;
    color: #006;
    text-transform: capitalize;
    padding-left: 40px;
    font-family: Georgia, 'Times New Roman', Times, serif;
    font-size: 18px;
    font-weight: 400;
    font-family: 'Roboto Slab', serif;
}

.list-item:hover {
    color: #ED1C25;
}

.ml-5 {
    margin-left: 20px !important;
}

.ml-5:hover {
    border-color: #ED1C25 !important;
}

.userName {
    color: #011E1B;
    margin-left: 5px;
}

.btn {
    margin-left: 15px;
    margin-right: 15px;
}

.btn-danger {
    font-family: Raleway-SemiBold;
    font-size: 18px;
    font-weight: bold;
    color: #ED1C25 ;
    letter-spacing: 1px;
    line-height: 15px;
    border: 2px solid #ED1C25 ;
    border-radius: 40px;
    background: transparent;
    transition: all 0.3s ease 0s;
  }
  
  .btn-danger:hover {
    color: #FFF;
    background: #ED1C25 ;
    border: 2px solid #ED1C25 ;
  }




@media (max-width: 992px) {
    .btn {
        margin-left: 57px;
        margin-right: 15px;
        margin-bottom: 20px;
    }

    .container-head {
        max-width: 80%;
    }
}


@media (max-width: 426px) {
    .list-item {
        padding: 15px 0;
    }

    .btn {
        margin: 15px auto;
    }
} */

@import url("https://fonts.googleapis.com/css2?family=Fira+Code:wght@300;400;500;600;700&family=Lora:ital,wght@0,400;0,500;0,600;1,400&family=Roboto+Slab:wght@300;400;500;600;700&display=swap");

.container-head {
  background: #fff;
  border-radius: 10px;
  transition: all 0.5s ease-in-out;
  padding: 0 30px;
  margin-top: 25px;
}

.head-bg {
  background-color: #011e1b;
}

.list-item {
  padding: 25px 0;
  display: inline-block;
  color: #006;
  text-transform: capitalize;
  padding-left: 40px;
  font-family: Georgia, "Times New Roman", Times, serif;
  font-size: 18px;
  font-weight: 400;
  font-family: "Roboto Slab", serif;
}

.list-item:hover {
  color: #ed1c25;
}

/* Style for NavDropdown title */
.nav-dropdown-title .dropdown-toggle {
  padding: 25px 0;
  display: inline-block;
  color: #006;
  text-transform: capitalize;
  padding-left: 40px;
  font-family: Georgia, "Times New Roman", Times, serif;
  font-size: 18px;
  font-weight: 400;
  font-family: "Roboto Slab", serif;
}

.nav-dropdown-title .dropdown-toggle:hover {
  color: #ed1c25;
}

.dropdown-item {
  color: #006 !important;
  font-family: "Roboto Slab", serif;
  font-size: 18px;
  font-weight: 400;
}

.dropdown-item:hover {
  color: #ed1c25 !important;
}

.ml-5 {
  margin-left: 20px !important;
}

.ml-5:hover {
  border-color: #ed1c25 !important;
}

.userName {
  color: #011e1b;
  margin-left: 5px;
}

.btn {
  margin-left: 15px;
  margin-right: 15px;
}

.btn-danger {
  font-family: Raleway-SemiBold;
  font-size: 18px;
  font-weight: bold;
  color: #ed1c25;
  letter-spacing: 1px;
  line-height: 15px;
  border: 2px solid #ed1c25;
  border-radius: 40px;
  background: transparent;
  transition: all 0.3s ease 0s;
}

.btn-danger:hover {
  color: #fff;
  background: #ed1c25;
  border: 2px solid #ed1c25;
}

/* for mobile responsive */
@media (max-width: 992px) {
  /* 2: Navbar */
  .btn {
    margin-left: 57px;
    margin-right: 15px;
    margin-bottom: 20px;
  }

  .container-head {
    max-width: 80%;
  }
}

@media (max-width: 426px) {
  .list-item {
    padding: 15px 0;
  }

  .btn {
    margin: 15px auto;
  }
}
@import url("https://fonts.googleapis.com/css2?family=Fira+Code:wght@300;400;500;600;700&family=Lora:ital,wght@0,400;0,500;0,600;1,400&family=Roboto+Slab:wght@300;400;500;600;700&display=swap");

.container-head {
  background: #fff;
  border-radius: 10px;
  transition: all 0.5s ease-in-out;
  padding: 0 30px;
  margin-top: 25px;
}

.head-bg {
  background-color: #011e1b;
}

.list-item {
  padding: 25px 0;
  display: inline-block;
  color: #006;
  text-transform: capitalize;
  padding-left: 40px;
  font-family: Georgia, "Times New Roman", Times, serif;
  font-size: 18px;
  font-weight: 400;
  font-family: "Roboto Slab", serif;
}

.list-item:hover {
  color: #ed1c25;
}

/* Style for NavDropdown title */
.nav-dropdown-title .dropdown-toggle {
  padding: 25px 0;
  display: inline-block;
  color: #006;
  text-transform: capitalize;
  padding-left: 40px;
  font-family: Georgia, "Times New Roman", Times, serif;
  font-size: 18px;
  font-weight: 400;
  font-family: "Roboto Slab", serif;
  margin-left: -10px;
  margin-top: -50px;
  margin-bottom: -50px;
}

.nav-dropdown-title .dropdown-toggle:hover {
  color: #ed1c25;
}

.dropdown-item {
  color: #006 !important;
  font-family: "Roboto Slab", serif;
  font-size: 18px;
  font-weight: 400;
}

.dropdown-item:hover {
  color: #ed1c25 !important;
}

.ml-5 {
  margin-left: 20px !important;
}

.ml-5:hover {
  border-color: #ed1c25 !important;
}

.userName {
  color: #011e1b;
  margin-left: 5px;
}

.btn {
  margin-left: 15px;
  margin-right: 15px;
}

.btn-danger {
  font-family: Raleway-SemiBold;
  font-size: 18px;
  font-weight: bold;
  color: #ed1c25;
  letter-spacing: 1px;
  line-height: 15px;
  border: 2px solid #ed1c25;
  border-radius: 40px;
  background: transparent;
  transition: all 0.3s ease 0s;
}

.btn-danger:hover {
  color: #fff;
  background: #ed1c25;
  border: 2px solid #ed1c25;
}

/* for mobile responsive */
@media (max-width: 992px) {
  /* 2: Navbar */
  .btn {
    margin-left: 57px;
    margin-right: 15px;
    margin-bottom: 20px;
  }

  .container-head {
    max-width: 80%;
  }
}

@media (max-width: 426px) {
  .list-item {
    padding: 15px 0;
  }

  .btn {
    margin: 15px auto;
  }
}
