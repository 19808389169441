/* styles.css
.whatsapp-button {
  position: fixed;
  bottom: 20px;
  left: 20px;
  background-color: white;
  border-radius: 50%;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.3);
  padding: 10px;
  cursor: pointer;
  z-index: 1000;
}

.whatsapp-button:hover {
  background-color: #e6e6e6;
} */


.whatsapp-button {
    position: fixed;
    bottom: 50px;
    right: 40px;
    background-color: #25D366;
    border-radius: 50%;
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.3);
    padding: 15px;
    cursor: pointer;
    z-index: 1000;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  
  .whatsapp-button:hover {
    background-color: #011E1B;
  }
  
  .whatsapp-button svg {
    color: #ffffff;
  }
  