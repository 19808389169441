.testimonial-wrapper {
  padding: 120px 0;
}

.client-box {
  border-radius: 10px;
  background-color: rgba(252, 81, 133, 0.05);
  box-shadow: 5.288px 11.876px 40px 0 rgb(0 0 0 / 5%);
  text-align: center;
  padding: 60px 58px 50px;
}

.client-img {
  position: relative;
  width: 233px;
  height: 233px;
  margin-bottom: 24px;
  overflow: hidden;
  border-radius: 50%;
  z-index: 1;
  margin: auto;
}

.client-img::before {
  background-color: #ED1C25;
  width: 100%;
  height: 100%;
  position: absolute;
  content: "";
  left: 0;
  top: 0;
  z-index: -1;
  border-radius: 50%;
}

.client-img img {
  position: absolute;
  text-align: center;
  left: 50%;
  bottom: 0;
  transform: translateX(-50%);
  z-index: 7;
}

.client-box h3 {
  font-size: 24px;
  line-height: 36px;
  font-family: "Josefin Sans", sans-serif;
}

.client-box span,
.review-item p {
  color: #666;
}

.review-item h5 {
  font-size: 18px;
  line-height: 26px;
  font-family: "Josefin Sans", sans-serif;
}

.testimonials {
  padding: 80px 0;
  background-color: #f9f9f9;
}

.section-title {
  text-align: center;
  margin-bottom: 50px;
}

.slick-slide {
  display: flex;
  justify-content: center;
  padding: 0 10px; /* Add padding to create margin between boxes */
}

.testimonial-item-wrapper {
  width: 100%;
  box-sizing: border-box;
}

.testimonial-item {
  background-color: #ffffff;
  padding: 30px;
  border-radius: 10px;
  box-shadow: 0px 0px 15px rgba(0, 0, 0, 0.1);
  margin: 0 10px; 
}

.quote {
  font-size: 18px;
  line-height: 1.6;
  color: #555555;
}

.testimonial-meta {
  margin-top: 20px;
}

.name {
  font-size: 20px;
  font-weight: bold;
  margin-bottom: 5px;
}

.position {
  font-size: 16px;
  color: #888888;
}

.slick-prev,
.slick-next {
  background-color: #000;
  border-radius: 50%;
  z-index: 1;
}
