.gradient-form {
    background-color: #011E1B;
}

.card-img {
    width: 185px;
}
.btn-floating {
    font-size: 40px !important;
}

.loginbtn {
    width: 38px !important;
    height: 38px !important;
    font-size: 27px !important;
    margin: 0 8px !important;
    line-height: 0px !important;
    padding: 4px;
    border: none;
}

.gradient-custom-2 {
    background: #fccb90;
    background: linear-gradient(to right, #ee7724, #d8363a, #dd3675, #b44593);
}
    
/* for mobile responsive */
@media (min-width: 769px) {
    .gradient-custom-2 {
        border-top-right-radius: .3rem;
        border-bottom-right-radius: .3rem;
    }
}