.footer-bg {
  background-color: #343a40;
  padding: 40px 0;
}

.footer-logo-container {
  text-align: center;
}

.footer-row {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: flex-start;
}

.single-footer-widget {
  text-align: left;
}

.footer-link {
  color: #fff;
  text-decoration: none;
  margin-bottom: 10px;
  display: block;
}

.footer-link:hover {
  text-decoration: underline;
}

.footer-copy-right {
  background-color: #23272b;
  padding: 10px 0;
}

.developer-link {
  color: #ffc107;
  text-decoration: none;
}

.developer-link:hover {
  text-decoration: underline;
}

/* Responsive adjustments */
@media (max-width: 767px) {
  .footer-row {
    flex-direction: column;
    align-items: center;
  }

  .single-footer-widget {
    text-align: center;
  }
}
